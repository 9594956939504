@import "../../../assets/scss/variables";

.rmService {
    &.servicePosition {
        position: relative;
        z-index: 9;
        top: -120px;
    }
}

.rmServiceWrapper {
    @media #{$md-layout,
    $sm-layout} {
        margin-top: -40px;
    }
}

.service {
    padding: 46px 38px;
    text-align: center;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2em;
    background: #FFF none repeat scroll 0 0;
    box-shadow: 0px 0px 20px rgba(15,0,220,0.10);
    &:hover {
        transform: translateY(-10px);
    }
    h2 {
        font-size: 24px;
        margin-top: 22px;
        transition: 0.3s;
        
        color: $theme-purple;
        background: -webkit-linear-gradient(left, $theme-purple, $theme-blue);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    p {
        margin: 0 0 1em 0;
    }
    a {
        color: $theme-blue!important;
        font-weight: 400;
        font-size: 1.05rem;
        &:hover {
            color: $theme-purple!important;
        }
    }
}

.rmService {
    &.servicePosition {
        position: relative;
        top: -120px;
        @media #{$lg-layout,
        $md-layout} {
            top: 0;

            padding: 120px 0;
        }
        @media #{
        $sm-layout} {
            top: 0;

            padding: 70px 0;
        }
    }
}
