@import "../../../assets/scss/variables";

.rmBusiness {
    .content {
        h2 {
            font-size: 36px;
            font-weight: 700;
            line-height: 40px;

            position: relative;

            margin-bottom: 20px;
            padding-bottom: 5px;

            color: $theme-purple;
            background: -webkit-linear-gradient(left, $theme-purple, $theme-blue);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }
        p {
            font-size: 17px;
            line-height: 27px;

            margin-bottom: 25px;

            color: $theme-purple;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.rmBusiness .thumb {
    position: relative;
    min-height: 500px; 
}

.rmBusiness .thumb img {
    height: 80%;
    border-radius: 3rem;
    box-shadow: 0px 0px 99px rgba(15,0,220,0.18);
    margin-top: 5rem;

    @media screen and (min-width: 768px){
        position: absolute;
        top: 10%;
        right: 10%;
        height: 85%;
        margin-top: 0;
    }
}
// .rmBusiness .thumb img {
//     width: 100%;
// }

@media #{$lg-layout} {

}

@media #{$md-layout} {
    .rmBusiness .thumb img {
        width: auto;
        height: 90%;
    }
    .rmBusiness .content h2 {
        font-size: 26px;
        line-height: 36px;

        margin-bottom: 24px;
        padding-bottom: 23px;
    }
    .rmBusiness .content p {
        font-size: 14px;
        line-height: 25px;

        margin-bottom: 13px;
    }
}

@media #{$sm-layout} {
    .rmBusiness .thumb img {
        width: 100%;
    }
    .rmBusiness .content h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 41px;

        position: relative;

        margin-bottom: 16px;
        padding-bottom: 15px;

        color: $theme-purple;
    }
    .rmBusiness .content h2 {
        font-size: 30px;
        line-height: 41px;

        margin-bottom: 16px;
        padding-bottom: 15px;
    }
}

@media #{$xs-layout} {

    .rmBusiness .content h2 {
        font-size: 24px;
        line-height: 36px;
    }
    .areaText::before {
        display: none;
    }
    .rmBusiness .content h2 {
        font-size: 32px;
        line-height: 41px;
    }
}

@media #{$xxs-layout} {
    .rmBusiness .thumb img {
        position: relative;
        width: 100%;
        height: auto;
    }
    .rmBusiness .content p {
        font-size: 14px;
        line-height: 24px;
    }
}
