@import "../../../assets/scss/variables";

.serviceTitle {
    h2 {
        font-size: 36px;
        line-height: 47px;

        position: relative;
        z-index: 1;

        display: inline-block;

        margin-bottom: 5px;

        color: $theme-purple;
        background: -webkit-linear-gradient(left, $theme-purple, $theme-blue);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media #{$sm-layout} {
            margin-top: -10px;
        }
    }
    p {
        font-size: 19px;
        padding: 24px 0;
        padding-right: 23%;

    }
}

.bestService {
    display: flex;
    flex-wrap: wrap;
    .icon {
        font-size: 40px;
        line-height: 123px;
        background-size: 56%;
        flex-basis: 31%;

        height: 74%;
        margin-left: 5px;

        text-align: center;
        @media #{$lg-layout} {
            flex-basis: 20%;
        }

        @media #{$sm-layout} {
            flex-basis: 25%;
        }

        @media #{$xs-layout} {
            flex-basis: 30%;
        }

        @media #{$xxs-layout} {
            flex-basis: 45%;
        }

        svg {
            color: $theme-blue;
        }
    }
    .content {
        flex-basis: 67%;
        @media #{$xxs-layout} {
            flex-basis: 100%;
        }

        h3 {
            font-size: 24px;
            line-height: 32px;

            margin-top: 18px;
            margin-bottom: 18px;

            transition: 0.4s;

            color: $theme-purple;
            background: -webkit-linear-gradient(left, $theme-purple, $theme-blue);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;    

            @media #{$xxs-layout} {
                font-size: 20px;
            }
        }
        p {
            margin: 0 0 22px 0;
        }
    }
}
