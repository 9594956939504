@import "../../../assets/scss/variables";

.heroSlider {
    .htSwiperButtonNav {
        font-size: 40px;

        width: 50px;
        height: 50px;
        padding: 0;

        color: #FFF;
        border: none;
        background: none;

        &:after {
            font-size: 0;
        }
    }

    .swiperButtonPrev {
        left: 50px;
    }

    .swiperButtonNext {
        right: 50px;
    }
}

.sliderOne {
    @media screen and (min-width: 768px) {
        margin-bottom: 10em;
    }
    .sliderFixedHeight {
        height: 900px;
        max-height: 90vh
    }
    .slide {
        padding-top: 100px;
    }
    .swiper-container {
        // overwrite le preset de swiper < overflow: hidden >
        overflow: unset!important;
    }
}


.graBg1 {
    background: linear-gradient(45deg, $theme-purple, $theme-blue);
}

.slide {
    .content {
        h1 {
            line-height: 65px;
            margin-bottom: 25px;
            margin-top: 2.5em;
            color: #FFFFFF;
            @media screen and (min-width: 768px){
            margin-top: inherit;
            width: 130%;
            }
        }
        p {
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
            margin-bottom: 40px;
            color: rgba(255,255,255, 0.8);
        }
    }
    .slideFrontImg {
        text-align: right;
        position: relative;
        @media screen and (min-width: 768px){
            height: 50vh;
        }
        img {
            border-radius: 3rem;
            box-shadow: 0px 0px 99px rgba(15,0,220,0.18);
            @media screen and (min-width: 768px){
                position: absolute;
                top: 20vh;
                left: 10%;
                height: 100%;
                margin-top: 5em;
            }
        }
    }
    .socialMedia {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -54px;
        a:hover svg {
            margin-top: -5px;
            height: 40px;
        }
        svg {
            transition: all 0.2s ease-in-out;
        }
    }
}



// Responsive Css

// @media #{$xlg-layout} {
//     .sliderOne .sliderFixedHeight {
//         height: 90vh;
//     }
// }

@media #{$lg-layout} {
    .slide .slideFrontImg img {
        height: 77%;
        border-radius: 2rem;

    }
    .sliderOne .sliderFixedHeight {
        height: 700px;
    }
}

@media #{$md-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 49px;
    }
    .slide .slideFrontImg img {
        height: 70%;
        border-radius: 2rem;
    }

    .sliderOne .sliderFixedHeight {
        height: 700px;
    }
}

@media #{$sm-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 45px;

        margin-bottom: 17px;
    }
    .slide .slideFrontImg img {
        width: 100%;
        height: auto;
        border-radius: 2rem;
    }
    .sliderOne .sliderFixedHeight {
        height: auto;
    }
    .sliderOne .sliderFixedHeight {
        padding-top: 167px;
        padding-bottom: 70px;
    }
    .slide .slideFrontImg {
        margin-top: 26px;

        text-align: center;
    }
}

