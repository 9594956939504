@import "../../../../assets/scss/variables";

.sectionTitle {
    span {
        font-size: 30px;
        font-weight: 300;
        line-height: 40px;

        color: #010F54;
    }
    h2 {
        display: inline;
        font-size: 36px;

        margin-top: 4px;
        margin-bottom: 50px;

        color: #3F0A61;
        background: -webkit-linear-gradient(left, $theme-purple, $theme-blue);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }
}

.sectionTitle2 {
    span {
        font-size: 18px;

        color: #010F54;
    }
    h2 {
        font-size: 36px;

        position: relative;

        margin-top: 4px;
        margin-bottom: 35px;
        padding-bottom: 40px;

        color: $theme-color;
        background: linear-gradient(110deg, $theme-purple, $theme-blue);
        -webkit-background-clip: text;
        background-size: 400%;

        -webkit-text-fill-color: transparent;

    }
}

@media #{$xlg-layout} {
    .sectionTitle2 h2 {
        margin-bottom: 22px;
        padding-bottom: 21px;
    }
}

@media #{$sm-layout} {
    .sectionTitle2 h2 {
        font-size: 27px;

        margin-bottom: 24px;
        padding-bottom: 21px;
    }
}

@media #{$xs-layout} {
    .sectionTitle2 h2 {
        font-size: 22px;

        margin-bottom: 24px;
    }
}

@media #{$xxs-layout} {
    .sectionTitle h2 {
        font-size: 27px;

        margin-top: 0;
    }
    .sectionTitle span {
        font-size: 24px;
    }
}
