@import "../../../assets/scss/variables";

.pricing_heading {
    text-align: center;
    border-radius: 3em;
    background: linear-gradient(to right, $theme-purple, $theme-blue);
    color: white;
    padding: 1.6em;
    margin: 7em 20px 0;
    h3 {
        opacity: 0.84;
        font-weight: 600;
    }
    .price {

        p {
            font-size: 40px;
            font-weight: 700;
            line-height: 47px;
            color: #fff;
            margin: 0;
            sup {
                top: -7px;
                left: 8px;
            }
            sub {
                font-size: 16px;

                left: -8px;
            }
        }
    }
    &:last-child {
        opacity: 1;
    }
}

.pricing {
    padding: 3em 0;
    margin: 40px 20px 40px;
    text-align: center;

    background: #FFF none repeat scroll 0 0;
    box-shadow: 0 0 10px rgba(1, 15, 10, 0.1);
    border-radius: 3em;
    .content {
        ul {
            margin: 0 0 17px;
            padding: 0;

            list-style: outside none none;
            li {
                font-size: 16px;
                font-weight: 300;

                position: relative;

                padding: 13px 0;

                color: #010F54;
                &:nth-child(n+5) {
                    font-weight: 500;
                }
            }
        }
        span {
            font-size: 18px;
            font-weight: 700;

            position: relative;
            z-index: 1;

            color: #010F54;
            &::before {
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;

                width: 70px;
                height: 70px;

                content: "";
                transform: translateX(-50%) translateY(-50%);

                border-radius: 100%;
                background: linear-gradient(-90deg, #FBFDFF, #E9F6FF);
            }
        }

    }
}
