@import "../../../assets/scss/variables";
.rmSoftware {
    .sftBgColor {
        padding-top: 172px;
        padding-bottom: 210px;

        background: linear-gradient(to left, $theme-blue, $theme-purple);
    }
    .shape1 {
        position: absolute;
        right: 0;
        bottom: 30px;
    }
    .content {
        h2 {
            font-size: 36px;
            line-height: 47px;

            color: #EEF3F6;
        }
        p {
            font-size: 16px;
            line-height: 26px;

            margin-bottom: 24px;

            color: #EEF3F6;
            & + p {
                margin: 0;
            }
        }
    }
}

.downloadBtn {
    display: flex;

    margin: 70px 0px 0;
    padding: 0;

    list-style: outside none none;
    li {
        margin: 0 20px;
        a {
            font-size: 16px;
            line-height: 50px;

            display: inline-block;

            height: 50px;
            padding: 0 40px;

            transition: all 0.5s ease 0s;
            text-align: center;

            color: #EEF3F6;
            border-radius: 26px;
            background: #010F54 none repeat scroll 0 0;
            img {
                padding-left: 20px;
            }
            &:hover {
                background: #E05941 none repeat scroll 0 0;
            }
        }
    }
}

.rmSoftware {
    .poly1 {
        position: absolute;
        top: 50px;
        left: 50px;
    }
    .poly2 {
        position: absolute;
        top: 215px;
        left: 236px;
    }
}

@media #{$xlg-layout} {
    .rmSoftware .shape1 {
        bottom: 50%;

        width: 50%;

        transform: translateY(50%);
    }
    .rmSoftware .shape1 img {
        width: 100%;
    }
    .rmSoftware {
        padding-bottom: 120px;
    }
}

@media #{$lg-layout} {
    .rmSoftware .shape1 {
        bottom: 50%;

        width: 50%;

        transform: translateY(50%);
    }
    .rmSoftware .shape1 img {
        width: 100%;
    }
    .rmSoftware {
        padding-bottom: 120px;
    }
    .downloadBtn {
        margin: 38px -20px 0;
    }
    .rmSoftware .sftBgColor {
        padding-top: 120px;
    }
}

@media #{$md-layout} {
    .rmSoftware .shape1 {
        bottom: 50%;

        width: 45%;

        transform: translateY(50%);
    }
    .rmSoftware .shape1 img {
        width: 100%;
    }
    .rmSoftware {
        padding-bottom: 120px;
    }
    .rmSoftware .sftBgColor {
        padding-top: 113px;
        padding-bottom: 57px;
    }
    .downloadBtn {
        margin: 22px -20px 0;
    }
}

@media #{$sm-layout} {
    .rmSoftware .shape1 {
        bottom: 50%;

        width: 50%;

        transform: translateY(50%);
    }
    .rmSoftware .shape1 img {
        width: 100%;
    }
    .rmSoftware {
        padding-bottom: 120px;
    }
    .rmSoftware .shape1 {
        position: inherit;
        bottom: 0;

        width: 100%;
        padding-top: 55px;

        transform: translateY(0px);
    }
    .rmSoftware .sftBgColor {
        padding-top: 50px;
    }
    .rmSoftware {
        padding-bottom: 60px;
    }
}

@media #{$xs-layout} {
    .rmSoftware .poly1 {
        top: 24px;
        left: 11px;
    }
    .rmSoftware .poly2 {
        top: 41px;
        left: 230px;
    }
}

@media #{$xxs-layout} {
    .rmSoftware .content h2 {
        font-size: 28px;
        line-height: 39px;
    }
    .rmSoftware .content p {
        font-size: 14px;
        line-height: 24px;

        margin-bottom: 12px;
    }
    .downloadBtn {
        flex-wrap: wrap;
        justify-content: center;

        margin: 30px -20px 0;
    }
    .downloadBtn li + li {
        margin-top: 20px;
    }
    .rmSoftware .sftBgColor {
        padding-top: 113px;
    }
}
